import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";

Vue.use(VueRouter)

const routes = [
  { path: "/", component: () => import("../views/HomeView.vue") },
  { path: "/admin", component: () => import("../views/Login.vue") },
  { path: "/admin-page", component: () => import("../views/AdminPage.vue") },
  { path: "/pacotes", component: () => import("../views/PacotesPage.vue") },
]

const baseURL = window.origin == 'http://localhost:8080' ? 'http://localhost:3000/' : 'https://vps48507.publiccloud.com.br/'
const http = axios.create({ baseURL });

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/') {
    return next()
  }
  const token = localStorage.getItem('token')
  if (to.path === '/admin-page' && !token) {
    alert('Autenticação necessária')
    return next({ path: "/", params: { nextUrl: to.fullPath } });
  }

  if (to.path === '/admin-page' && token) {
    let error = null
    await http.post('verify-hash', {token}).then(resp => {
      resp.data.type == 'error' && (error = true);
    })
    if (error) {
      return next({ path: "/", params: { nextUrl: to.fullPath } });
    }
    return next()
  }

  if (to.path === '/admin-page' && !token) {
    alert('Autenticação necessária')
    return next({ path: "/", params: { nextUrl: to.fullPath } });
  } else {
    return next()
  }
})

export default router
