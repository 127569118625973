import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/css/style.css'
import './assets/css/global.css'
import axios from 'axios'
import mixin from './mixin/index'
import Toast from "vue-toastification";
import 'vue-toastification/dist/index.css';

Vue.mixin(mixin)
Vue.use(Toast, {
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
})


const baseUrl = window.origin == 'http://localhost:8080' ? 'http://localhost:3000/' : 'https://vps48507.publiccloud.com.br/'

//console.log(`origin: ${window.origin}`)
//console.log(`base: ${baseUrl}`)
const http = axios.create({ baseURL: baseUrl })

Vue.prototype.$axios = axios;
Vue.prototype.$http = http;
Vue.prototype.$baseUrl = baseUrl;
Vue.config.productionTip = false



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
