import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    small: false,
    colors: {
      primary: 'red',
      secondary: 'blue'
    }
  },
  getters: {
  },
  mutations: {
    setColors(state, colors) {
      state.colors = colors;
    },
  },
  actions: {
  },
  modules: {
  }
})
