const mixin = {
  data() {
    return {
    }
  },
  methods: {
    async HTTP(metodo, url, params = null) {
      return new Promise((resolve, reject) => {
        const headers = {
          headers: {
            token: localStorage.getItem('token'),
            User: localStorage.getItem('user'),
          }
        }
        metodo = metodo.toLowerCase();
        try {
          if (!params) {
            this.$http[metodo](url, headers).then(async result => {
              if (result.data.type === 'error') {
                result?.data?.msg && this.$toast.error(result.data.msg)
                reject(result.data);
              }
              if (result.data.type === 'info') {
                result?.data?.msg && this.$toast.info(result.data.msg)
                reject(result.data);
              }
              result?.data?.msg && result.data.type === 'success' && this.$toast.success(result.data.msg)
              result.data.rows = result.data.rows ?? [];
              resolve(result.data.rows)
            }).catch((err) => {
              this.$toast.error('Erro de conexão')
              reject(err)
            })
          } else {
            this.$http[metodo](url, params, headers).then(async result => {
              if (result.data.type === 'error') {
                result?.data?.msg && this.$toast.error(result.data.msg)
                reject(result.data);
              }
              if (result.data.type === 'info') {
                result?.data?.msg && this.$toast.info(result.data.msg)
                result?.data?.rows ? reject(result?.data?.rows) : reject(result.data)
              }
              result?.data?.msg && result.data.type === 'success' && this.$toast.success(result.data.msg)
              result.data.rows = result.data.rows ?? [];
              resolve(result.data.rows)
            }).catch((err) => {
              this.$toast.error('Erro de conexão')
              reject(err)
            })
          }
        } catch (e) {
          this.$toast.error('Algo deu errado, contate o t.i')
          reject()
        }
      })
    },
  }
 };

export default mixin;