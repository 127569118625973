import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: {
          base: '#8dbedb',
          text: '#000000',
        },
        secondary: {
          base: '#d1e2f0',
          text: '#000000',
        },
      },
    },
  },
});